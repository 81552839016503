.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

.about {
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.MuiPaper-root {
  display: flex !important;
  flex-direction: column !important;
  gap: 1px !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #fff !important;
}

.MuiPaper-root > * {
  background-color: #000 !important;
  color: #fff !important;
}

.MuiAccordionDetails-root {
  color: rgba(186, 186, 186, 0.644) !important;
}